import {
  Card,
  CardContent,
  // CardFooter,
  CardHeader,
} from "../../../components\u0017/ui/card";
import { useParams } from "react-router-dom";
import { usePost } from "../../../services/hooks/post";

import PostHeader from "./postHeader";
import PostContent from "./postContent";

// type Props = {};

export const Post = () => {
  const { id } = useParams();

  console.log(id, "id");

  const { isLoading, data } = usePost(id as string);

  if (isLoading) {
    return <div className="container">loading</div>;
  }

  console.log(data?.post, "data?.data post");
  return (
    <div
      className="container px-2 md:px-6 py-10 w-[400px] 
    sm:w-[500px] md:w-[800px]"
    >
      <Card className="w-full">
        <CardHeader>
          <PostHeader post={data?.post} />
        </CardHeader>
        <CardContent className="flex flex-col pb-0">
          <PostContent post={data?.post} />
        </CardContent>
        {/* <CardFooter className="flex justify-between"></CardFooter> */}
      </Card>
    </div>
  );
};

export default Post;
