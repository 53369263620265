import { Post_Endpoints } from "../types/endpoint";
import Instance from "./api";

export default class Post {
  private static Post = "/post";

  static fetchSinglePost(id: string) {
    return Instance.get(
      `${Post.Post}/${Post_Endpoints.FETCH_SINGLE_POST}/${id}`
    );
  }
  static postComment(data: {
    message: string;
    postId: string;
    parentId: string | null;
  }) {
    return Instance.post(`/${Post_Endpoints.ADD_COMMENT}`, data);
  }

  static async fetchPostEngagement(postId: string, userId?: string) {
    console.log(postId,' in route')
    const url = `${Post.Post}/${Post_Endpoints.ENGAGEMENT}?postId=${postId}`;
    const fullUrl = userId ? `${url}&userId=${userId}` : url;

    try {
      const response = await Instance.get(fullUrl);
      return response.data;
    } catch (error) {
      console.error("Error fetching post engagement:", error);
      throw error;
    }
  }

  static async addPostLike(postId: string) {
    const url = `${Post.Post}/${Post_Endpoints.LIKE}?postId=${postId}`;
    try {
      const response = await Instance.post(url);
      return response.data;
    } catch (error) {
      console.error("Error post like", error);
      throw error;
    }
  }

  static async fetchComments({
    pageParam = 1,
    postId,
  }: {
    pageParam: number;
    postId: string;
  }) {
    const response = await Instance.get(
      `/${Post_Endpoints.GET_COMMENTS}?postId=${postId}&page=${pageParam}`
    );
    const { data, totalCount, hasMore } = response.data;

    return {
      data,
      nextPage: hasMore ? pageParam + 1 : undefined,
    };
  }
}
