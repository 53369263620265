import {
  Carousel,
  CarouselContent,
  CarouselItem,
  // CarouselNext,
  // CarouselPrevious,
} from "../../../components\u0017/ui/carousel";
import { Separator } from "../../../components\u0017/ui/separator";
import { cn } from "../../../lib/utils";
import { PostI } from "../../../types/post";
import PostAddComments from "./postAddComments";
import PostComments from "./postComments";
import PostEngament from "./postEngagement";

type Props = {
  post?: PostI;
};

function PostContent({ post }: Props) {
  return (
    <>
      <Carousel className="w-full relative">
        <CarouselContent>
          {post?.images.map((image, index) => (
            <CarouselItem key={index}>
              <div className="relative w-full">
                <div
                  className={cn(
                    "relative w-full rounded-lg overflow-hidden"
                  )}
                  style={{ position: "relative" }}
                >
                  <img
                    src={image.url}
                    alt={image.id}
                    className="w-full h-auto object-contain rounded-lg"
                  />
                  {image.sensitivePixels.map((pixel, idx) => (
                    <div
                      key={idx}
                      className="absolute bg-black/40 backdrop-blur-md"
                      style={{
                        top: `${pixel.y * 100}%`,
                        left: `${pixel.x * 100}%`,
                        width: `${pixel.width * 100}%`,
                        height: `${pixel.height * 100}%`,
                      }}
                    />
                  ))}
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>

      <div className="mt-4"></div>
      <PostEngament post={post} />
      <Separator />
      <PostAddComments
        postId={post?.id}
        isTopLevel={true}
        hasSubmit={() => {}}
      />
      <PostComments postId={post?.id as string} />
    </>
  );
}

export default PostContent;