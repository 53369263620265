import { MoreVertical } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components\u0017/ui/avatar";
import { Button } from "../../../components\u0017/ui/button";
import { CardDescription, CardTitle } from "../../../components\u0017/ui/card";

import { PostI } from "../../../types/post";
type Props = {
  post?: PostI;
};

function PostHeader({ post }: Props) {
  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-start items-center capitalize">
          <div>
            <Avatar>
              <AvatarImage
                src={"../../../placeholder.jpeg"}
                alt="userProfile"
              />
              {/* <AvatarImage src="https://github.com/shadcn.png" /> */}
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
          </div>
          <div className="ml-4">
            <CardTitle className="text-lg font-semibold">
              {post?.user?.username}
            </CardTitle>
          </div>
        </div>
        <div>
          <Button variant="ghost" size="icon">
            <MoreVertical className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <CardDescription className="pt-2 md:pt-4 text-sm md:text-base">
      {blurKeywords(post?.caption, post?.sensitiveMeta?.keywords)}
      </CardDescription>
    </>
  );
}

export default PostHeader;

const blurKeywords = (
  text?: string,
  keywords?: string,
) => {
  const sanitizedText = text?.replace(/\b\.(?=\s|$)/g, '');
  const sensitiveText = sanitizedText?.split(' ');
  const sensitive = keywords?.split(' ');

  return (
    <p className="flex flex-wrap gap-1">
      {sensitiveText?.map((word, index) => {
        const isBlurred = sensitive?.includes(word);
        return (
          <span
            key={`${text}-${index}`}
            className={isBlurred ? "blur-sm text-transparent bg-gray-400 rounded-md px-1" : ""}
          >
            {word + (index < sensitiveText.length - 1 ? ' ' : '')}
          </span>
        );
      })}
    </p>
  );
};