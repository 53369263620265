import Navbar from "../components\u0017/custom/navbar";
import logo from "../assets/public/logo.png";
import angle from "../assets/public/angle.png";
import phone from "../assets/public/phone.png";
import play from "../assets/public/play.png";
import apple from "../assets/public/apple.png";
import { Button } from "../components\u0017/ui/button";
import Footer from "../components\u0017/custom/footer";

type Props = {};

export const Home = ({}: Props) => {
  return (
    <div className="h-screen">
      <div className="bg-primary min-h-96 pb-32">
        <Navbar></Navbar>
        <div className="flex justify-center items-center flex-col mt-10 px-4 md:px-0">
          <img src={logo} alt="" className="w-96 md:w-[700px]" />
          <h1 className="text-4xl md:text-6xl  text-white font-bold text-center mt-10 font-dancing">
            A community of anonymous <br /> people forewarning one another.
          </h1>
        </div>
      </div>
      <div className="container py-32  discription text-xl md:text-2xl text-black font-light leading-8 md:leading-[50px]">
        <p className="xl:px-64">
          TeaReceipts is an online community where people can anonymously warn
          each other about other people or businesses, sharing distasteful
          experiences freely without fear of retaliation. TeaReceipts is unique
          from different social media platforms or review services because the
          users and accused parties are anonymous. All posts must be accompanied
          by a receipts such as a screenshot to support all claims. Accused
          parties remain anonymous, only revealing them when users search their
          sensitive information like phone numbers or full names.
        </p>
        <div className="relative flex justify-end">
          <img
            src={angle}
            alt=""
            className="w-[500px] absolute -top-25 md:-top-20"
          />
        </div>
      </div>
      <div className="download bg-primary py-20 mt-10">
        <div className="container flex flex-col md:flex-row">
          <div className="flex-1 text-center md:text-start">
            <h2 className="text-white text-4xl md:text-6xl font-semibold leading-10 md:leading-[80px] mt-20">
              Enjoy creating a fairer world with awareness
            </h2>
            <p className="text-2xl text-black mt-10">
              TeaReceipts is a solution that brings awareness to the <br />{" "}
              unjust things that are done in the dark.
            </p>
            <div className="flex mt-20 justify-center md:justify-start">
              <Button>
                <a
                  href="https://play.google.com/store/apps/details?id=com.teareceipts"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex justify-center lg:justify-start items-center bg-black rounded p-2 w-40 lg:w-48">
                    <div className="w-10">
                      <img src={play} alt="" className="w-8 md:w-10" />
                    </div>
                    <div className="uppercase text-white text-start ml-2">
                      <p>get it on</p>
                      <p className="text-xs md:text-xl">google play</p>
                    </div>
                  </div>
                </a>
              </Button>
              <Button>
                <a
                  href="https://apps.apple.com/pk/app/teareceipts/id6741158446"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex justify-center lg:justify-start items-center bg-black rounded p-2 w-40 lg:w-48">
                    <div className="w-10">
                      <img src={apple} alt="" className="w-8 md:w-10" />
                    </div>
                    <div className="uppercase text-white text-start ml-2">
                      <p>get it on</p>
                      <p className="text-xs md:text-xl">App Store</p>
                    </div>
                  </div>
                </a>
              </Button>
            </div>
          </div>
          <div className="flex-1 flex justify-center lg:justify-end">
            <img src={phone} alt="" className="w-96" />
          </div>
        </div>
      </div>
      <div className="container flex flex-col justify-center items-center pt-20 pb-32">
        <div className="text-center">
          <p className="text-black font-light">Our projections in numbers</p>
          <h2 className="text-4xl text-subleHard mt-8">
            Reaching people nation wide and <br />
            growing.
          </h2>
        </div>
        <div className="mt-20 md:mt-32 flex text-center md:text-start flex-col md:flex-row justify-between w-full">
          <div>
            <span className="text-primary text-6xl">1m</span>
            <p className="text-sm md:text-xl text-suble mt-4 font-light ml-4">
              Current amount of users
            </p>
          </div>
          <div className="mt-10 md:mt-0">
            <span className="text-primary text-6xl">1,111,111</span>
            <p className="text-sm md:text-xl text-suble mt-4 font-light ml-4">
              Amount of posts
            </p>
          </div>
          <div className="mt-10 md:mt-0">
            <span className="text-primary text-6xl">777 </span>
            <p className="text-sm md:text-xl text-suble mt-4 font-light ml-4">
              Present in multiple cities
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
