type Props = {};

export const SafetyAndContentPolicy = ({}: Props) => {
  return (
    <div className="container py-20">
      <div className="space-y-6 md:mx-20">
        <div className="mb-14">
          <h1 className="text-4xl font-bold">
            Safety & Content <span className="ml-2 text-primary">Policy</span>
          </h1>
          <div className="line h-1 w-40 bg-primary rounded-md mt-2"></div>
        </div>

        <div>
          <h2 className="text-xl font-bold text-subleHard mb-4">
            TeaReceipts Content Policy: Zero Tolerance for CSAE
          </h2>
          <p className="text-lg text-suble">
            At TeaReceipts, we are committed to ensuring a safe, ethical, and law-abiding platform for anonymous discussions. We strictly prohibit any form of Child Sexual Abuse and Exploitation (CSAE) and take immediate action against any violations.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Prohibited Content and Activities
          </h2>
          <p className="text-lg text-suble mb-2">
            TeaReceipts does not allow any content, media, or activity that involves or promotes:
          </p>
          <ul className="list-disc ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              Child Sexual Abuse Material (CSAM) – including images, videos, or descriptions.
            </li>
            <li className="text-lg text-suble">
              Grooming or predatory behavior – including attempts to engage minors in inappropriate conversations or relationships.
            </li>
            <li className="text-lg text-suble">
              Sexual exploitation of minors – including trafficking, coercion, or other forms of abuse.
            </li>
            <li className="text-lg text-suble">
              Links, references, or discussions that promote or normalize child exploitation.
            </li>
            <li className="text-lg text-suble">
              Any illegal content related to minors that violates local or international laws.
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Enforcement and Reporting
          </h2>
          <p className="text-lg text-suble mb-2">
            We take CSAE violations very seriously and enforce strict actions, including:
          </p>
          <ul className="list-disc ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              Content Removal: Any content violating this policy will be immediately removed.
            </li>
            <li className="text-lg text-suble">
              Account Termination: Users who engage in CSAE-related content or behavior will be permanently banned from TeaReceipts.
            </li>
            <li className="text-lg text-suble">
              Reporting to Authorities: We report all CSAE violations to the National Center for Missing & Exploited Children (NCMEC) and other relevant law enforcement agencies.
            </li>
            <li className="text-lg text-suble">
              User Reporting System: If you encounter any content violating this policy, please report it through our in-app reporting system or contact our compliance team at info@teareceipts.com.
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Legal Compliance
          </h2>
          <p className="text-lg text-suble mb-2">
            TeaReceipts fully complies with all applicable laws and industry standards, including:
          </p>
          <ul className="list-disc ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              U.S. PROTECT Our Children Act
            </li>
            <li className="text-lg text-suble">
              The National Center for Missing & Exploited Children (NCMEC) guidelines
            </li>
            <li className="text-lg text-suble">
              Google Play and Apple App Store policies on CSAE prevention
            </li>
          </ul>
          <p className="text-lg text-suble mt-4">
            We actively monitor, detect, and remove prohibited content while working with authorities to ensure our platform remains a safe space for all users.
          </p>
        </div>

        <div className="mt-8">
          <p className="text-lg text-suble font-bold">
            TeaReceipts has a strict ZERO-TOLERANCE policy for CSAE. Any violation will lead to immediate action, including law enforcement involvement.
          </p>
        </div>
      </div>
    </div>
  );
};