// api.js
import axios, { AxiosError, AxiosResponse } from "axios";

// intances
const instance = axios.create({
  baseURL: "https://api.teareceipts.com/v2/",
  // baseURL: "http://localhost:8000/v2/",
  withCredentials: true,
});

// interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response && error.response.status === 406) {
    //   handle403();
    // }
    return Promise.reject(error);
  }
);

// const handle403 = () => {
//   instance.post("/auth/logout");
//   window.location.href = '/sign-in';
// };

interface CustomErrorResponse {
  message: string;
}

export interface CustomAxiosError extends Omit<AxiosError, 'response'> {
  response?: AxiosResponse<CustomErrorResponse>;
}


export default instance;