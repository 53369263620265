import PostSkeleton from "../../components/custom/postSkeleton";
import Navbar from "../../components/custom/teareceipts/navbar";
import { Separator } from "../../components/ui/separator";
import { Skeleton } from "../../components/ui/skeleton";

type Props = {};

export const DashboardPage = ({}: Props) => {
  return (
    <div>
      <div className="container mt-10 flex justify-between">
        <div className="w-[600px] space-y-6 hidden lg:block">
          <div className="flex gap-2 items-end">
            <div>
              <Skeleton className="h-10 w-10 rounded-full" />
            </div>
            <div className="w-full space-y-2">
              <Skeleton className="h-2 w-20" />
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
          <div className="flex gap-2 items-end">
            <div>
              <Skeleton className="h-10 w-10 rounded-full" />
            </div>
            <div className="w-full space-y-2">
              <Skeleton className="h-2 w-20" />
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
          <div className="flex gap-2 items-end">
            <div>
              <Skeleton className="h-10 w-10 rounded-full" />
            </div>
            <div className="w-full space-y-2">
              <Skeleton className="h-2 w-20" />
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
          <div className="flex gap-2 items-end">
            <div>
              <Skeleton className="h-10 w-10 rounded-full" />
            </div>
            <div className="w-full space-y-2">
              <Skeleton className="h-2 w-20" />
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
          <div className="flex gap-2 items-end">
            <div>
              <Skeleton className="h-10 w-10 rounded-full" />
            </div>
            <div className="w-full space-y-2">
              <Skeleton className="h-2 w-20" />
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
          <div className="flex gap-2 items-end">
            <Skeleton className="h-44 w-[250px]" />
          </div>
        </div>
        <div className="w-full space-y-14 h-calc-remaining overflow-scroll main">
          <PostSkeleton />
          <PostSkeleton />
          <PostSkeleton />
          <PostSkeleton />
        </div>
        <div className="w-[600px] flex-col items-end space-y-4 hidden lg:flex">
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-44 w-[250px]" />
          <Skeleton className="h-44 w-[250px]" />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
